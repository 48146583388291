import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Link as MuiLink,
} from '@mui/material';
import { LockOpen, PersonAdd } from '@mui/icons-material';
import CONFIG from '../utils/config';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const quotes = [
    {
      text: 'There is no greater agony than bearing an untold story inside you.',
      author: 'Maya Angelou',
    },
    {
      text: 'The scariest moment is always just before you start.',
      author: 'Stephen King',
    },
    {
      text: 'You can make anything by writing.',
      author: 'C.S. Lewis',
    },
    {
      text: 'Fill your paper with the breathings of your heart.',
      author: 'William Wordsworth',
    },
    {
      text: 'You can make things that never existed before or that never would have existed otherwise. If you have the right words, you can do anything.',
      author: 'C.S. Lewis',
    },
    {
      text: 'Almost all good writing begins with terrible first efforts. You need to start somewhere.',
      author: 'Anne Lamott',
    },
    {
      text: 'The writer is someone for whom writing is more difficult than it is for other people.',
      author: 'Thomas Mann',
    },
    {
      text: 'I write entirely to find out what I’m thinking, what I’m looking at, what I see and what it means.',
      author: 'Joan Didion',
    },
    {
      text: "Writing is its own reward. What I like about writing is that it's not about what I think anybody else is going to think about it, but what I think about it. And if I like it, that's all that matters.",
      author: 'Brandon Sanderson',
    },
  ];

  const [displayedText, setDisplayedText] = useState('');
  const [displayedAuthor, setDisplayedAuthor] = useState('');
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    const shuffledQuotes = [...quotes].sort(() => Math.random() - 0.5);
    let currentIndex = 0;

    const typeNextQuote = () => {
      clearTimeout(typingTimeoutRef.current);

      const quoteElement = document.querySelector('.quote-container');
      if (quoteElement) {
        quoteElement.style.opacity = 0;
      }

      typingTimeoutRef.current = setTimeout(() => {
        setDisplayedText('');
        setDisplayedAuthor('');

        if (quoteElement) {
          quoteElement.style.opacity = 1;
        }

        const { text, author } = shuffledQuotes[currentIndex];
        const fullText = text;
        const fullAuthor = `— ${author}`;

        let charIndex = 0;

        const typeCharacter = () => {
          if (charIndex <= fullText.length) {
            setDisplayedText(fullText.substring(0, charIndex));
            charIndex++;

            let typingSpeed = 100;

            typingTimeoutRef.current = setTimeout(typeCharacter, typingSpeed);
          } else {
            typingTimeoutRef.current = setTimeout(() => {
              let authorCharIndex = 0;

              const typeAuthor = () => {
                if (authorCharIndex <= fullAuthor.length) {
                  setDisplayedAuthor(fullAuthor.substring(0, authorCharIndex));
                  authorCharIndex++;
                  typingTimeoutRef.current = setTimeout(typeAuthor, 100);
                } else {
                  typingTimeoutRef.current = setTimeout(() => {
                    currentIndex = (currentIndex + 1) % shuffledQuotes.length;
                    typeNextQuote();
                  }, 5000);
                }
              };

              typeAuthor();
            }, 1000);
          }
        };

        typeCharacter();
      }, 1000);
    };

    typeNextQuote();

    return () => {
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const button = document.querySelector('.login-button');
    button.classList.add('explode');
    setTimeout(() => button.classList.remove('explode'), 500);

    try {
      const res = await axios.post(`${CONFIG.SERVER_IP}/api/auth/login`, {
        username: username.toLowerCase(),
        password,
      });
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('username', username.toLowerCase());
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      alert('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="site-name">geniescribe.ai</div>
      <Paper elevation={6} className="login-box">
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: '10px' }}
        >
          Login
        </Typography>
        <form onSubmit={handleSubmit} className="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
            InputProps={{
              style: { color: '#000' },
            }}
            InputLabelProps={{
              style: { color: '#000' },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            InputProps={{
              style: { color: '#000' },
            }}
            InputLabelProps={{
              style: { color: '#000' },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="login-button"
          >
            Login
          </Button>
          <br />
          <MuiLink
            component={Link}
            to="/reset-password"
            variant="body2"
            className="signup-link"
          >
            <LockOpen style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            Forgot your password?
          </MuiLink>
          <br />
          <MuiLink
            component={Link}
            to="/signup"
            variant="body2"
            className="signup-link"
          >
            <PersonAdd style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            Don't have an account? Sign up
          </MuiLink>
        </form>
      </Paper>
      <div className="quote-container">
        <span className="typewriter">{displayedText}</span>
        <br />
        <span className="author">{displayedAuthor}</span>
      </div>
    </div>
  );
};

export default Login;

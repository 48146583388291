import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    FaBook, FaCalendarWeek, FaCogs
} from 'react-icons/fa';
import '../styles/Home.css';
import CONFIG from '../utils/config';

function Home() {
    const [usageStats, setUsageStats] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsageStats = async () => {
            try {
                const username = sessionStorage.getItem('username');
                const response = await axios.get(`${CONFIG.SERVER_IP}/api/usage/stats`, {
                    params: { username }
                });
                setUsageStats(response.data);
                console.log('Usage stats fetched:', response.data);
            } catch (error) {
                console.error('Error fetching usage stats:', error);
                setError(error);
            }
        };

        fetchUsageStats();
    }, []);

    if (error) {
        return <div>Error fetching usage stats. Please try again later.</div>;
    }

    if (!usageStats) {
        return <div>Loading...</div>;
    }

    return (
        <div className="home-container">
            <div className="stats-header">
                <h1>Welcome Back!</h1>
                <p>Here's an overview of your activity.</p>
            </div>

            <div className="stats-overview">
                <div className="stats-card">
                    <FaBook className="stats-icon" />
                    <h3>Total Books Generated</h3>
                    <p>{usageStats.totalBooks}</p>
                </div>
                <div className="stats-card">
                    <FaCalendarWeek className="stats-icon" />
                    <h3>Last 7 Days Usage</h3>
                    <p>Input Tokens: {usageStats.last7Days.inputTokens}</p>
                    <p>Output Tokens: {usageStats.last7Days.outputTokens}</p>
                    <p>Total Tokens: {usageStats.last7Days.totalTokens}</p>
                </div>
                <div className="stats-card">
                    <FaCogs className="stats-icon" />
                    <h3>Total API Calls (Last 7 Days)</h3>
                    <p>{usageStats.last7Days.totalCalls}</p>
                </div>
            </div>
        </div>
    );
}

export default Home;

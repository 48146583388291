import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CONFIG from '../utils/config';
import '../styles/Login.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${CONFIG.SERVER_IP}/api/auth/reset-password`, { email });
      alert('Password reset email sent. Please check your inbox.');
      navigate('/login');
    } catch (error) {
      console.error('Reset password error:', error);
      alert('Error sending reset email. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="site-name">geniescribe.ai</div>
      <Paper elevation={6} className="login-box">
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form onSubmit={handleReset} className="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="login-button"
          >
            Send Reset Link
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default ResetPassword;
// Level2.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import {
  Person as PersonIcon,
  Topic as TopicIcon,
  Category as CategoryIcon,
  Style as StyleIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  HelpOutline as HelpOutlineIcon,
  Title as TitleIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import '../styles/Level2.css';

const Level2 = ({ setView, bookInfo, setBookInfo }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [persona, setPersona] = useState(bookInfo.persona || 'businessLeader');
  const [topic, setTopic] = useState(bookInfo.topic || '');
  const [field, setField] = useState(bookInfo.field || '');
  const [style, setStyle] = useState(bookInfo.style || '');
  const [targetAudience, setTargetAudience] = useState(bookInfo.targetAudience || '');
  const [problem, setProblem] = useState(bookInfo.problem || '');
  const [bookTitle, setBookTitle] = useState(bookInfo.bookTitle || '');

  const [errors, setErrors] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);

  const steps = [
    'Choose Persona',
    'Main Topic',
    'Related Field',
    'Writing Style',
    'Target Audience',
    'Main Problem',
    'Book Title',
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    // Validate the input of the current step before moving to the next
    const newErrors = {};
    if (activeStep === 1 && !topic.trim())
      newErrors.topic = 'Topic is required';
    if (activeStep === 2 && !field.trim())
      newErrors.field = 'Field is required';
    if (activeStep === 3 && !style.trim())
      newErrors.style = 'Style is required';
    if (activeStep === 4 && !targetAudience.trim())
      newErrors.targetAudience = 'Target audience is required';
    if (activeStep === 5 && !problem.trim())
      newErrors.problem = 'Problem is required';
    if (activeStep === 6 && !bookTitle.trim())
      newErrors.bookTitle = 'Book title is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      if (activeStep === steps.length - 1) {
        // All steps completed, open confirmation dialog
        setConfirmOpen(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setErrors({});
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirm = () => {
    // Set the bookInfo state
    setBookInfo({
      ...bookInfo,
      persona,
      topic,
      field,
      style,
      targetAudience,
      problem,
      bookTitle,
      level: 2,
    });
    setConfirmOpen(false);
    setView('outline', { level: 2 });
  };

  return (
    <Box className="level2-container">
      <Box className="level2-banner">
        <Typography variant="h3" className="level2-title">
          <b>Insight Enhancer</b>
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel className="stepper">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content">
        {activeStep === 0 && (
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend" className="level2-form-label">
              <PersonIcon className="form-icon" /> <b>Choose a Persona</b>
            </FormLabel>
            <RadioGroup
              aria-label="persona"
              name="persona"
              value={persona}
              onChange={(e) => setPersona(e.target.value)}
            >
              <FormControlLabel
                value="businessLeader"
                control={<Radio className="level2-radio" />}
                label="Business Leader"
              />
              <FormControlLabel
                value="academicResearcher"
                control={<Radio className="level2-radio" />}
                label="Academic/Researcher"
              />
              <FormControlLabel
                value="selfHelpGuru"
                control={<Radio className="level2-radio" />}
                label="Self-help Guru"
              />
            </RadioGroup>
          </FormControl>
        )}
        {activeStep === 1 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <TopicIcon className="form-icon" /> <b>Main Topic or Subject</b>
            </FormLabel>
            <TextField
              fullWidth
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter the main topic or subject"
              variant="outlined"
              error={!!errors.topic}
              helperText={errors.topic}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <CategoryIcon className="form-icon" /> <b>Related Field or Area</b>
            </FormLabel>
            <TextField
              fullWidth
              value={field}
              onChange={(e) => setField(e.target.value)}
              placeholder="Enter the field or area"
              variant="outlined"
              error={!!errors.field}
              helperText={errors.field}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <StyleIcon className="form-icon" /> <b>Preferred Writing Style or Tone</b>
            </FormLabel>
            <TextField
              fullWidth
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              placeholder="Enter the preferred style or tone"
              variant="outlined"
              error={!!errors.style}
              helperText={errors.style}
            />
          </Box>
        )}
        {activeStep === 4 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <GroupIcon className="form-icon" /> <b>Who is your target audience?</b>
            </FormLabel>
            <TextField
              fullWidth
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              placeholder="Enter your target audience"
              variant="outlined"
              error={!!errors.targetAudience}
              helperText={errors.targetAudience}
            />
          </Box>
        )}
        {activeStep === 5 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <HelpOutlineIcon className="form-icon" />{' '}
              <b>Main Problem, Question, or Challenge</b>
            </FormLabel>
            <TextField
              fullWidth
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              placeholder="Describe the main problem or challenge"
              variant="outlined"
              error={!!errors.problem}
              helperText={errors.problem}
            />
          </Box>
        )}
        {activeStep === 6 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level2-form-label">
              <TitleIcon className="form-icon" /> <b>Preferred Book Title or Ideas</b>
            </FormLabel>
            <TextField
              fullWidth
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              placeholder="Enter your book title ideas"
              variant="outlined"
              error={!!errors.bookTitle}
              helperText={errors.bookTitle}
            />
          </Box>
        )}
        <Box className="step-buttons">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="back-button"
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className="next-button"
            endIcon={
              activeStep === steps.length - 1 ? <DescriptionIcon /> : null
            }
          >
            {activeStep === steps.length - 1 ? 'Generate Outline' : 'Next'}
          </Button>
        </Box>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-dialog"
        maxWidth="sm"
        fullWidth
        classes={{ paper: 'confirm-dialog-paper' }}
      >
        <DialogTitle id="confirm-dialog" className="confirm-dialog-title">
          <CheckCircleIcon className="confirm-dialog-icon" />
          Confirm Your Selections
        </DialogTitle>
        <DialogContent dividers>
          <Box className="confirm-content">
            <Typography variant="h6" className="confirm-label">
              Persona:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {persona === 'businessLeader' && 'Business Leader'}
              {persona === 'academicResearcher' && 'Academic/Researcher'}
              {persona === 'selfHelpGuru' && 'Self-help Guru'}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Topic:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {topic}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Related Field:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {field}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Writing Style:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {style}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Target Audience:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {targetAudience}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Problem:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {problem}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Book Title:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {bookTitle}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className="confirm-dialog-actions">
          <Button
            onClick={() => setConfirmOpen(false)}
            className="dialog-back-button"
          >
            Back
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            className="dialog-confirm-button"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Level2;
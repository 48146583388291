import React, { useEffect, useState } from 'react';
import { Box, useTheme, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Description from './Description';
import Outline from './Outline';
import ChapterGenerator from './ChapterGenerator';
import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';
import Level4 from './Level4';
import Sidebar from './Sidebar';
import Home from './Home';
import PlayGround from './PlayGround';
import '../styles/Dashboard.css';

const drawerWidth = 240;

const Dashboard = () => {
  const [view, setView] = useState('home');
  const [currentLevel, setCurrentLevel] = useState(1);
  const [bookInfo, setBookInfo] = useState({
    // Shared book info if needed
  });

  const [level1Info, setLevel1Info] = useState({
    persona: '',
    topic: '',
    field: '',
    style: '',
  });

  const [level2Info, setLevel2Info] = useState({
    persona: '',
    topic: '',
    field: '',
    style: '',
    targetAudience: '',
    problem: '',
    bookTitle: '',
  });

  const [level3Info, setLevel3Info] = useState({
    persona: '',
    topic: '',
    field: '',
    style: '',
    targetAudience: '',
    problem: '',
    bookTitle: '',
    keyPoints: '',
    background: '',
    examples: '', 
  });

  const [level4Info, setLevel4Info] = useState({
    persona: '',
    topic: '',
    field: '',
    style: '',
    targetAudience: '',
    problem: '',
    bookTitle: '',
    keyPoints: '',
    background: '',
    examples: '',
    chapters: [],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (view === 'chapterGenerator') {
      setDrawerOpen(false); // Collapse the sidebar
    }
  }, [view]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const setViewAndLevel = (newView, level = null) => {
    setView(newView);
    if (level !== null) {
      setCurrentLevel(level);
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }} className="dashboard-container">
      <Sidebar
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setView={setViewAndLevel}
        isMobile={isMobile}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { xs: '100%', sm: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)` },
          marginLeft: { sm: 0 },
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
        className="main-content"
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: 'none' },
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: 1,
              boxShadow: 3,
              '&:hover': {
                backgroundColor: 'white',
                boxShadow: 6,
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            maxWidth: '100%',
            padding: isMobile ? '16px' : '24px 24px 24px 24px',
          }}
        >
          {view === 'home' ? (
            <Home />
          ) : view === 'Beginner' ? (
            <Level1
              setView={(v) => setViewAndLevel(v, 1)}
              bookInfo={level1Info}
              setBookInfo={setLevel1Info}
            />
          ) : view === 'Novice' ? (
            <Level2
              setView={(v) => setViewAndLevel(v, 2)}
              bookInfo={level2Info}
              setBookInfo={setLevel2Info}
            />
          ) : view === 'Intermediate' ? (
            <Level3
              setView={(v) => setViewAndLevel(v, 3)}
              bookInfo={level3Info}
              setBookInfo={setLevel3Info}
            />
          ): view === 'Advanced' ? (
            <Level4
              setView={(v) => setViewAndLevel(v, 4)}
              bookInfo={level4Info}
              setBookInfo={setLevel4Info}
            />
          ) : view === 'yourBooks' ? (
            <ChapterGenerator
              setView={setView}
              setDrawerOpen={setDrawerOpen} // Pass setDrawerOpen to ChapterGenerator
              bookInfo={bookInfo}
              setBookInfo={setBookInfo}
            />
          ) : view === 'description' ? (
            <Description
              setView={setView}
              setBookInfo={setBookInfo}
              bookInfo={bookInfo}
            />
          ) : view === 'outline' ? (
            <Outline
              setView={setViewAndLevel}
              bookInfo={
                currentLevel === 1
                  ? level1Info
                  : currentLevel === 2
                  ? level2Info
                  : currentLevel === 3
                  ? level3Info
                  : currentLevel === 4
                  ? level4Info
                  : bookInfo
              }
              setBookInfo={
                currentLevel === 1
                  ? setLevel1Info
                  : currentLevel === 2
                  ? setLevel2Info
                  : currentLevel === 3
                  ? setLevel3Info
                  : currentLevel === 4
                  ? setLevel4Info
                  : setBookInfo
              }
              level={currentLevel}
            />
          ) : view === 'generateOutline' ? (
            <Outline
              setView={setViewAndLevel}
              bookInfo={
                currentLevel === 1
                  ? level1Info
                  : currentLevel === 2
                  ? level2Info
                  : currentLevel === 3
                  ? level3Info
                  : currentLevel === 4
                  ? level4Info
                  : bookInfo
              }
              setBookInfo={
                currentLevel === 1
                  ? setLevel1Info
                  : currentLevel === 2
                  ? setLevel2Info
                  : currentLevel === 3
                  ? setLevel3Info
                  : currentLevel === 4
                  ? setLevel4Info
                  : setBookInfo
              }
              level={currentLevel}
            />
          ) : view === 'chapterGenerator' ? (
            <ChapterGenerator
              setView={setView}
              bookInfo={
                currentLevel === 1
                  ? level1Info
                  : currentLevel === 2
                  ? level2Info
                  : currentLevel === 3
                  ? level3Info
                  : currentLevel === 4
                  ? level4Info
                  : bookInfo
              }
              setBookInfo={
                currentLevel === 1
                  ? setLevel1Info
                  : currentLevel === 2
                  ? setLevel2Info
                  : currentLevel === 3
                  ? setLevel3Info
                  : currentLevel === 4
                  ? setLevel4Info
                  : setBookInfo
              }
              level={currentLevel}
              setDrawerOpen={setDrawerOpen} // Pass setDrawerOpen to ChapterGenerator
            />
          ) : view === 'playground' ? (
            <PlayGround />
          ) : (
            // ... other views
            null
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert, IconButton, Tooltip, MenuItem, Select, InputLabel, FormControl, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { FaUpload, FaFileAlt } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import CONFIG from '../utils/config';
import '../styles/Description.css';

const Description = ({ setView, setBookInfo, bookInfo }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const username = sessionStorage.getItem('username') || 'defaultUser';
    
    const fetchFiles = async () => {
      try {
        console.log(`Fetching files for user: ${username}`); // Debugging log
        const response = await axios.get(`${CONFIG.SERVER_IP}/api/upload/files?username=${username}`);
        console.log('Files fetched:', response.data.files); // Debugging log
        setUploadedFiles(response.data.files || []);
      } catch (error) {
        console.error('Error loading files:', error);
        setUploadedFiles([]);
      }
    };
    
    
    fetchFiles(); // Fetch files when the component is mounted
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookInfo({ ...bookInfo, [name]: value });
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('username', sessionStorage.getItem('username') || 'defaultUser');

      try {
        const response = await axios.post(`${CONFIG.SERVER_IP}/api/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const newFiles = response.data.files || [];
        if (!Array.isArray(newFiles)) {
          throw new Error("Invalid response format: 'files' is not an array.");
        }

        setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
        setSuccess('Files uploaded successfully');
      } catch (error) {
        console.error('Error uploading files:', error);
        setError(error.response ? error.response.data.error : 'Error uploading files');
      } finally {
        setSnackbarOpen(true);
        setLoading(false);
      }
    }
  };

  const handleDeleteFile = async (fileName) => {
    const username = sessionStorage.getItem('username') || 'defaultUser';
    try {
      await axios.delete(`${CONFIG.SERVER_IP}/api/upload/file`, {
        data: { username, fileName },
      });
      setUploadedFiles(uploadedFiles.filter(file => file !== fileName));
      setSuccess('File deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Error deleting file');
      setSnackbarOpen(true);
    }
  };

  const handleClearDescription = () => {
    if (window.confirm('Are you sure you want to clear the description?')) {
      setBookInfo({ ...bookInfo, description: '' });
    }
  };

  const handleNextStep = async () => {
      if (!bookInfo.title || !bookInfo.description) {
          setError('Please provide both a title and a description.');
          setSnackbarOpen(true);
          return;
      }

      const username = sessionStorage.getItem('username') || 'defaultUser';
      sessionStorage.setItem('username', username);
      const timestamp = new Date().toISOString();

      try {
          // Send the book info to your server to save without awaiting
          axios.post(`${CONFIG.SERVER_IP}/api/books/save`, {
              ...bookInfo,
              username,
              timestamp,
          });
      } catch (error) {
          console.error('Error saving book:', error);
          setError('Error saving book');
          setSnackbarOpen(true);
      }

      // Immediately move to triggering vector store creation without waiting
      try {
          // Send a request to the backend to trigger the vector store creation without awaiting
          axios.post(`${CONFIG.SERVER_IP}/api/upload/trigger-vector-store`, {
              user_id: username,
          });
      } catch (error) {
          console.error('Error triggering vector store creation:', error);
          // Optional: Set error for vector store trigger, if needed
      }

      // Continue to the next steps without waiting for the POST requests to complete
      setSuccess('Book saved and Vector Store creation triggered successfully');
      setError('');
      setSnackbarOpen(true);
      setBookInfo({
          ...bookInfo,
          rawOutline: '',
          tableOfContent: [],
          chapters: [],
      });

      setView('outline');
  };


      const handleCloseSnackbar = () => {
          setSnackbarOpen(false);
      };


  return (
    <Box className="content-description">
      <Box className="container-form">
        <Box className="form-header">
          <Box>
            <Typography variant="h4" className="form-title">
              Your Book, My Magic
            </Typography>
            <Typography variant="body2" className="form-subtitle">
              Share your vision, and the genie will do the rest
            </Typography>
          </Box>
        </Box>
        <Box className="form-element">
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={bookInfo.title}
            onChange={handleChange}
            className="input-single"
          />
        </Box>
        <Box className="form-element">
          <Box className="description-container">
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              name="description"
              value={bookInfo.description}
              onChange={handleChange}
              multiline
              rows={10}  // Increased height
              className="input-multiple"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Clear Description">
                    <IconButton onClick={handleClearDescription} className="clear-icon">
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Box>
        </Box>
        <Box className="form-element-bottom">
          <FormControl variant="outlined" className="select-chapters" fullWidth>
            <InputLabel
              id="numChapters-label"
              sx={{
                fontSize: '1.2rem', /* Increased font size */
                fontWeight: 'bold', /* Bold font weight */
                color: '#ffffff', /* White color for better contrast */
                paddingBottom: '4px', /* Adds some spacing below the label */
              }}
            >
              Number of Chapters
            </InputLabel>
            <Select
              labelId="numChapters-label"
              id="numChapters"
              name="numChapters"
              value={bookInfo.numChapters}
              onChange={handleChange}
              label="Number of Chapters"
              sx={{ 
                fontSize: '1rem', 
                padding: '1px', 
                backgroundColor: '#333333',
                color: '#ffffff'
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 100,
                    backgroundColor: '#ffffff',
                    color: '#333333'
                  },
                },
              }}
            >
              {[...Array(20).keys()].map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="form-element-bottom button-container">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<FaUpload />}
            onClick={() => document.getElementById('file-upload').click()}
          >
            Upload Files
          </Button>
          <input
            id="file-upload"
            type="file"
            accept=".txt, .docx, .pdf"
            onChange={handleFileUpload}
            multiple
            style={{ display: 'none' }}
          />
          <Button variant="contained" color="primary" onClick={handleNextStep}>
            Next Step
          </Button>
        </Box>
        {uploadedFiles.length > 0 && (
            <Box className="uploaded-files">
              <Typography variant="h6">Uploaded Files</Typography>
              <List>
                {uploadedFiles.map((file, index) => (
                  <ListItem key={index} className="file-item">
                    <FaFileAlt className="file-icon" />
                    <ListItemText primary={file} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleDeleteFile(file)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        {error && (
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default Description;

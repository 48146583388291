import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CONFIG from '../utils/config';
import '../styles/Login.css';

const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();

  const handleSetPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${CONFIG.SERVER_IP}/api/auth/update-password`, { token, newPassword: password });
      alert('Password updated successfully. Please log in with your new password.');
      navigate('/login');
    } catch (error) {
      console.error('Set new password error:', error);
      alert('Error updating password. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="site-name">geniescribe.ai</div>
      <Paper elevation={6} className="login-box">
        <Typography component="h1" variant="h5">
          Set New Password
        </Typography>
        <form onSubmit={handleSetPassword} className="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="new-password"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="login-button"
          >
            Update Password
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default SetNewPassword;
// Level3.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import {
  Person as PersonIcon,
  Topic as TopicIcon,
  Category as CategoryIcon,
  Style as StyleIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  HelpOutline as HelpOutlineIcon,
  Title as TitleIcon,
  List as ListIcon,
  AccountCircle as AccountCircleIcon,
  InsertDriveFile as InsertDriveFileIcon,
  // MenuBook as MenuBookIcon, // Commented out as per your request
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import '../styles/Level3.css';

const Level3 = ({ setView, bookInfo, setBookInfo }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [persona, setPersona] = useState(bookInfo.persona || 'businessLeader');
  const [topic, setTopic] = useState(bookInfo.topic || '');
  const [field, setField] = useState(bookInfo.field || '');
  const [style, setStyle] = useState(bookInfo.style || '');
  const [targetAudience, setTargetAudience] = useState(bookInfo.targetAudience || '');
  const [problem, setProblem] = useState(bookInfo.problem || '');
  const [bookTitle, setBookTitle] = useState(bookInfo.bookTitle || '');
  const [keyPoints, setKeyPoints] = useState(bookInfo.keyPoints || '');
  const [background, setBackground] = useState(bookInfo.background || '');
  const [examples, setExamples] = useState(bookInfo.examples || '');
  // const [desiredLength, setDesiredLength] = useState(bookInfo.desiredLength || ''); // Commented out

  const [errors, setErrors] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);

  const steps = [
    'Choose Persona',
    'Main Topic',
    'Related Field',
    'Writing Style',
    'Target Audience',
    'Main Problem',
    'Book Title',
    'Key Points',
    'Your Background',
    'Examples or Data',
    // 'Desired Length', // Commented out
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    // Validate the input of the current step before moving to the next
    const newErrors = {};
    if (activeStep === 1 && !topic.trim())
      newErrors.topic = 'Topic is required';
    if (activeStep === 2 && !field.trim())
      newErrors.field = 'Field is required';
    if (activeStep === 3 && !style.trim())
      newErrors.style = 'Style is required';
    if (activeStep === 4 && !targetAudience.trim())
      newErrors.targetAudience = 'Target audience is required';
    if (activeStep === 5 && !problem.trim())
      newErrors.problem = 'Problem is required';
    if (activeStep === 6 && !bookTitle.trim())
      newErrors.bookTitle = 'Book title is required';
    if (activeStep === 7 && !keyPoints.trim())
      newErrors.keyPoints = 'Key points are required';
    if (activeStep === 8 && !background.trim())
      newErrors.background = 'Background is required';
    if (activeStep === 9 && !examples.trim())
      newErrors.examples = 'Examples are required';
    // if (activeStep === 10 && !desiredLength.trim())
    //   newErrors.desiredLength = 'Desired length is required'; // Commented out

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      if (activeStep === steps.length - 1) {
        // All steps completed, open confirmation dialog
        setConfirmOpen(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setErrors({});
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirm = () => {
    // Set the bookInfo state
    setBookInfo({
      ...bookInfo,
      persona,
      topic,
      field,
      style,
      targetAudience,
      problem,
      bookTitle,
      keyPoints,
      background,
      examples,
      //desiredLength, // Commented out
      level: 3,
    });
    setConfirmOpen(false);
    setView('outline', { level: 3 });
  };

  return (
    <Box className="level3-container">
      <Box className="level3-banner">
        <Typography variant="h3" className="level3-title">
          <b>Insight Innovator</b>
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel className="stepper">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content">
        {activeStep === 0 && (
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend" className="level3-form-label">
              <PersonIcon className="form-icon" /> <b>Choose a Persona</b>
            </FormLabel>
            <RadioGroup
              aria-label="persona"
              name="persona"
              value={persona}
              onChange={(e) => setPersona(e.target.value)}
            >
              <FormControlLabel
                value="businessLeader"
                control={<Radio className="level3-radio" />}
                label="Business Leader"
              />
              <FormControlLabel
                value="academicResearcher"
                control={<Radio className="level3-radio" />}
                label="Academic/Researcher"
              />
              <FormControlLabel
                value="selfHelpGuru"
                control={<Radio className="level3-radio" />}
                label="Self-help Guru"
              />
            </RadioGroup>
          </FormControl>
        )}
        {activeStep === 1 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <TopicIcon className="form-icon" /> <b>Main Topic or Subject</b>
            </FormLabel>
            <TextField
              fullWidth
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter the main topic or subject"
              variant="outlined"
              error={!!errors.topic}
              helperText={errors.topic}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <CategoryIcon className="form-icon" /> <b>Related Field or Area</b>
            </FormLabel>
            <TextField
              fullWidth
              value={field}
              onChange={(e) => setField(e.target.value)}
              placeholder="Enter the field or area"
              variant="outlined"
              error={!!errors.field}
              helperText={errors.field}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <StyleIcon className="form-icon" /> <b>Preferred Writing Style or Tone</b>
            </FormLabel>
            <TextField
              fullWidth
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              placeholder="Enter the preferred style or tone"
              variant="outlined"
              error={!!errors.style}
              helperText={errors.style}
            />
          </Box>
        )}
        {activeStep === 4 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <GroupIcon className="form-icon" /> <b>Who is your target audience?</b>
            </FormLabel>
            <TextField
              fullWidth
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              placeholder="Enter your target audience"
              variant="outlined"
              error={!!errors.targetAudience}
              helperText={errors.targetAudience}
            />
          </Box>
        )}
        {activeStep === 5 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <HelpOutlineIcon className="form-icon" />{' '}
              <b>Main Problem, Question, or Challenge</b>
            </FormLabel>
            <TextField
              fullWidth
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              placeholder="Describe the main problem or challenge"
              variant="outlined"
              error={!!errors.problem}
              helperText={errors.problem}
            />
          </Box>
        )}
        {activeStep === 6 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <TitleIcon className="form-icon" /> <b>Preferred Book Title or Ideas</b>
            </FormLabel>
            <TextField
              fullWidth
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              placeholder="Enter your book title ideas"
              variant="outlined"
              error={!!errors.bookTitle}
              helperText={errors.bookTitle}
            />
          </Box>
        )}
        {activeStep === 7 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <ListIcon className="form-icon" />{' '}
              <b>3-5 Key Points or Insights</b>
            </FormLabel>
            <TextField
              fullWidth
              value={keyPoints}
              onChange={(e) => setKeyPoints(e.target.value)}
              placeholder="Enter key points separated by commas"
              variant="outlined"
              error={!!errors.keyPoints}
              helperText={errors.keyPoints}
            />
          </Box>
        )}
        {activeStep === 8 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <AccountCircleIcon className="form-icon" />{' '}
              <b>Your Background Related to This Topic</b>
            </FormLabel>
            <TextField
              fullWidth
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="Enter your background related to this topic"
              variant="outlined"
              error={!!errors.background}
              helperText={errors.background}
            />
          </Box>
        )}
        {activeStep === 9 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <InsertDriveFileIcon className="form-icon" />{' '}
              <b>Specific Examples, Case Studies, or Data</b>
            </FormLabel>
            <TextField
              fullWidth
              value={examples}
              onChange={(e) => setExamples(e.target.value)}
              placeholder="Enter examples, case studies, or data"
              variant="outlined"
              error={!!errors.examples}
              helperText={errors.examples}
            />
          </Box>
        )}
        {/* Commented out desired length step
        {activeStep === 10 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level3-form-label">
              <MenuBookIcon className="form-icon" />{' '}
              <b>Desired Length or Scope of Your Book</b>
            </FormLabel>
            <TextField
              fullWidth
              value={desiredLength}
              onChange={(e) => setDesiredLength(e.target.value)}
              placeholder="Enter desired length or scope"
              variant="outlined"
              error={!!errors.desiredLength}
              helperText={errors.desiredLength}
            />
          </Box>
        )}
        */}
        <Box className="step-buttons">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="back-button"
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className="next-button"
            endIcon={
              activeStep === steps.length - 1 ? <DescriptionIcon /> : null
            }
          >
            {activeStep === steps.length - 1 ? 'Generate Outline' : 'Next'}
          </Button>
        </Box>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-dialog"
        maxWidth="sm"
        fullWidth
        classes={{ paper: 'confirm-dialog-paper' }}
      >
        <DialogTitle id="confirm-dialog" className="confirm-dialog-title">
          <CheckCircleIcon className="confirm-dialog-icon" />
          Confirm Your Selections
        </DialogTitle>
        <DialogContent dividers>
          <Box className="confirm-content">
            <Typography variant="h6" className="confirm-label">
              Persona:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {persona === 'businessLeader' && 'Business Leader'}
              {persona === 'academicResearcher' && 'Academic/Researcher'}
              {persona === 'selfHelpGuru' && 'Self-help Guru'}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Topic:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {topic}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Related Field:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {field}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Writing Style:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {style}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Target Audience:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {targetAudience}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Problem:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {problem}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Book Title:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {bookTitle}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Key Points:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {keyPoints}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Your Background:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {background}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Examples or Data:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {examples}
            </Typography>
            {/* Commented out desired length
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Desired Length:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {desiredLength}
            </Typography>
            */}
          </Box>
        </DialogContent>
        <DialogActions className="confirm-dialog-actions">
          <Button
            onClick={() => setConfirmOpen(false)}
            className="dialog-back-button"
          >
            Back
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            className="dialog-confirm-button"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Level3;
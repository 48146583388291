import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Typography, Container, Paper, Link as MuiLink } from '@mui/material';
import CONFIG from '../utils/config';
import '../styles/Signup.css';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${CONFIG.SERVER_IP}/api/auth/signup`, { username, email, password });
      alert('Signup successful');
      navigate('/login');
    } catch (error) {
      alert('Signup failed');
    }
  };

  return (
    <div className="signup-container">
      <Paper elevation={6} className="signup-box">
        <Typography component="h1" variant="h5">
          Signup
        </Typography>
        <Typography variant="body1" align="center" style={{ marginBottom: '20px', fontStyle: 'italic' }}>
          "Writing is the painting of the voice." - Voltaire
        </Typography>
        <form onSubmit={handleSubmit} className="signup-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="signup-input"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="signup-button"
          >
            Signup
          </Button>
          <MuiLink component={Link} to="/login" variant="body2" className="login-link">
            Already have an account? Login
          </MuiLink>
        </form>
      </Paper>
    </div>
  );
};

export default Signup;

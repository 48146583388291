// frontend/src/components/pages/PlayGround.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  FormControl,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Book as BookIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import '../styles/PlayGround.css';
import axios from 'axios';
import CONFIG from '../utils/config';
import ReactMarkdown from 'react-markdown';

const PlayGround = () => {
  const [title, setTitle] = useState('');
  const [outline, setOutline] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [improvedText, setImprovedText] = useState('');
  const [notes, setNotes] = useState('');
  const [fullBookContent, setFullBookContent] = useState('');
  const [showFullBookDialog, setShowFullBookDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [numChapters, setNumChapters] = useState(2);
  const [wordsPerChapter, setWordsPerChapter] = useState(500);
  const [audience, setAudience] = useState('');
  const [genre, setGenre] = useState('');
  const [style, setStyle] = useState('');

  const genreOptions = [
    'Fantasy',
    'Science Fiction',
    'Mystery',
    'Romance',
    'Horror',
    'Thriller',
    'Comedy', // Added 'Comedy' to genre options
  ];
  const styleOptions = [
    'Serious',
    'Humorous',
    'Romantic',
    'Informative',
    'Dark',
    'Inspirational',
  ];
  const audienceOptions = [
    'Children',
    'Young Adults',
    'Adults',
    'Fantasy Lovers',
    'Science Fiction Enthusiasts',
  ];

  const handleGenerateOutline = async () => {
    try {
      setLoading(true);
      const username = sessionStorage.getItem('username');
      const response = await axios.post(
        `${CONFIG.SERVER_IP}/api/playground/generate-outline`,
        {
          title,
          username,
          numChapters,
          audience,
          genre,
          style,
        }
      );
      setOutline(response.data.outline);
    } catch (error) {
      console.error('Error generating outline:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateChapter = async (chapterIndex) => {
    try {
      setLoading(true);
      const username = sessionStorage.getItem('username');
      const chapterTitle = outline[chapterIndex].title;
      const previousChapterContent = chapterIndex > 0 ? outline[chapterIndex - 1].content : '';
      const nextChapterTitle = chapterIndex < outline.length - 1 ? outline[chapterIndex + 1].title : '';

      const response = await axios.post(
        `${CONFIG.SERVER_IP}/api/playground/generate-chapter`,
        {
          title,
          chapterTitle,
          chapterIndex,
          username,
          wordsPerChapter,
          previousChapterContent,
          nextChapterTitle,
          genre,
          style,
        }
      );
      const updatedOutline = [...outline];
      updatedOutline[chapterIndex].content = response.data.content;
      setOutline(updatedOutline);
    } catch (error) {
      console.error('Error generating chapter:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectText = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    if (selectedText.length > 0) {
      setSelectedText(selectedText);
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const [selectionRange, setSelectionRange] = useState(null);

  const handleImproveText = async () => {
    try {
      setLoading(true);
      const username = sessionStorage.getItem('username');
      const response = await axios.post(
        `${CONFIG.SERVER_IP}/api/playground/improve-text`,
        {
          selectedText,
          notes,
          username,
        }
      );
      setImprovedText(response.data.improvedText);
    } catch (error) {
      console.error('Error improving text:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReplaceText = () => {
    if (selectionRange && improvedText) {
      const chapterIndex = findChapterIndexBySelection(selectionRange);
      if (chapterIndex !== -1) {
        const updatedOutline = [...outline];
        const chapterContent = updatedOutline[chapterIndex].content;

        const newContent = chapterContent.replace(selectedText, improvedText);
        updatedOutline[chapterIndex].content = newContent;
        setOutline(updatedOutline);
      }
      setSelectedText('');
      setImprovedText('');
      setNotes('');
    }
  };

  const findChapterIndexBySelection = (range) => {
    const selectedNode = range.startContainer.parentNode;
    for (let i = 0; i < outline.length; i++) {
      const chapterContentElement = document.getElementById(`chapter-content-${i}`);
      if (chapterContentElement && chapterContentElement.contains(selectedNode)) {
        return i;
      }
    }
    return -1;
  };

  const handleViewFullBook = () => {
    let content = '';
    outline.forEach((chapter, index) => {
      content += `# Chapter ${index + 1}: ${chapter.title}\n\n${chapter.content}\n\n`;
    });
    setFullBookContent(content);
    setShowFullBookDialog(true);
  };

  const handleDownloadBook = async () => {
    try {
      const response = await axios.post(
        `${CONFIG.SERVER_IP}/api/playground/download-book`,
        {
          title,
          outline,
        },
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'text/markdown' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title.replace(/\s+/g, '_')}.md`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading book:', error);
    }
  };

  // Input validation for numChapters
  const handleNumChaptersChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[1-9]\d*$/.test(value)) {
      setNumChapters(value);
    }
  };

  return (
    <Box className="playground-container" onMouseUp={handleSelectText}>
      {/* Left Sidebar */}
      <Box className="left-sidebar">
        <Typography variant="h5" className="sidebar-title">
          <b>Story Options</b>
        </Typography>

        <Divider className="divider" />

        <FormControl fullWidth margin="normal">
          <TextField
            label="Book Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter Fiction Book Title"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Number of Chapters"
            type="number"
            value={numChapters}
            onChange={handleNumChaptersChange}
            placeholder="Enter number of chapters"
            InputProps={{ inputProps: { min: 1 } }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Words per Chapter"
            type="number"
            value={wordsPerChapter}
            onChange={(e) => setWordsPerChapter(e.target.value)}
            placeholder="Approximate Words per Chapter"
          />
        </FormControl>

        {/* Updated Autocomplete components to allow custom input */}
        <Autocomplete
          freeSolo
          options={genreOptions}
          inputValue={genre}
          onInputChange={(event, newInputValue) => {
            setGenre(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Genre" margin="normal" fullWidth />
          )}
        />

        <Autocomplete
          freeSolo
          options={styleOptions}
          inputValue={style}
          onInputChange={(event, newInputValue) => {
            setStyle(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Style" margin="normal" fullWidth />
          )}
        />

        <Autocomplete
          freeSolo
          options={audienceOptions}
          inputValue={audience}
          onInputChange={(event, newInputValue) => {
            setAudience(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Audience" margin="normal" fullWidth />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateOutline}
          disabled={!title || loading}
          className="generate-outline-button"
        >
          Generate Outline
        </Button>

        <Divider className="divider" />

        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={handleViewFullBook}
          disabled={outline.length === 0}
          className="view-full-book-button"
        >
          View Full Book
        </Button>

        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadBook}
          disabled={outline.length === 0}
          className="download-book-button"
        >
          Download Book
        </Button>
      </Box>

      {/* Main Content Area */}
      <Box className="main-content">
        {/* Header */}
        <Typography variant="h4" className="playground-title" gutterBottom>
          <b>{title || 'Your Story Will Appear Here'}</b>
        </Typography>

        <Divider className="divider" />

        {/* Chapters List */}
        {loading && (
          <Box className="loading-indicator">
            <CircularProgress color="secondary" />
          </Box>
        )}
        {outline.length > 0 ? (
          <List>
            {outline.map((chapter, chapterIndex) => (
              <Box key={chapterIndex} className="chapter-box">
                <ListItem>
                  <ListItemText
                    primary={<b>{`Chapter ${chapterIndex + 1}: ${chapter.title}`}</b>}
                  />
                  <div className="chapter-buttons">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleGenerateChapter(chapterIndex)}
                      disabled={loading}
                      startIcon={<BookIcon />}
                      className="generate-chapter-button"
                    >
                      Generate
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        const updatedOutline = [...outline];
                        updatedOutline[chapterIndex].content = '';
                        setOutline(updatedOutline);
                      }}
                      disabled={loading}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </div>
                </ListItem>
                <Typography
                  variant="body1"
                  className="chapter-content"
                  id={`chapter-content-${chapterIndex}`}
                  dangerouslySetInnerHTML={{
                    __html: chapter.content.replace(/\n/g, '<br/>'),
                  }}
                ></Typography>
                <Divider className="divider" />
              </Box>
            ))}
          </List>
        ) : (
          <Typography variant="h6" align="center" className="no-content-text">
            No chapters generated yet. Please use the form to generate an outline.
          </Typography>
        )}
      </Box>

      {/* Improve Text Dialog */}
      <Dialog
        open={selectedText && selectedText.length > 0}
        onClose={() => setSelectedText('')}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <b>Improve Selected Text</b>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" className="selected-text">
            {selectedText}
          </Typography>
          <TextField
            label="Notes/Instructions"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={4}
            fullWidth
            placeholder="Add any notes or instructions for improvement"
            margin="normal"
          />
          {improvedText && (
            <>
              <Typography variant="h6">
                <b>Improved Text:</b>
              </Typography>
              <Typography variant="body1" className="improved-text">
                {improvedText}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!improvedText ? (
            <Button onClick={handleImproveText} color="primary" disabled={loading}>
              Submit for Improvement
            </Button>
          ) : (
            <>
              <Button onClick={handleReplaceText} color="primary">
                Replace Text
              </Button>
              <Button
                onClick={() => {
                  setImprovedText('');
                  setSelectedText('');
                  setNotes('');
                }}
              >
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Full Book Dialog */}
      <Dialog
        open={showFullBookDialog}
        onClose={() => setShowFullBookDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <b>{title}</b>
        </DialogTitle>
        <DialogContent>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <ReactMarkdown>{fullBookContent}</ReactMarkdown>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFullBookDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PlayGround;

// frontend/src/components/pages/ChapterGenerator.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  MenuBook as MenuBookIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Highlight as HighlightIcon,
  Undo as UndoIcon,
  Save as SaveIcon,
  Edit as EditIcon,
  AutoFixHigh as AIFixIcon,
} from '@mui/icons-material';
import axios from 'axios';
import CONFIG from '../utils/config';
import '../styles/ChapterGenerator.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const parseMarkdown = (text) => {
  const rules = [
    { regex: /###### (.*$)/gim, replacement: '<h6>$1</h6>' },
    { regex: /##### (.*$)/gim, replacement: '<h5>$1</h5>' },
    { regex: /#### (.*$)/gim, replacement: '<h4>$1</h4>' },
    { regex: /### (.*$)/gim, replacement: '<h3>$1</h3>' },
    { regex: /## (.*$)/gim, replacement: '<h2>$1</h2>' },
    { regex: /# (.*$)/gim, replacement: '<h1>$1</h1>' },
    { regex: /\*\*(.*)\*\*/gim, replacement: '<b>$1</b>' },
    { regex: /\*(.*)\*/gim, replacement: '<i>$1</i>' },
    { regex: /!\[(.*?)\]\((.*?)\)/gim, replacement: '<img alt="$1" src="$2" />' },
    { regex: /\[(.*?)\]\((.*?)\)/gim, replacement: '<a href="$2">$1</a>' },
    { regex: /- (.*$)/gim, replacement: '<li>$1</li>' },
    { regex: /\n{3,}/gim, replacement: '\n\n' }, // Replace 3 or more newlines with 2
    { regex: /\n{2,}/gim, replacement: '</p><p>' }, // Replace 2 or more newlines with paragraph breaks
    { regex: /\n/gim, replacement: '<br>' }, // Replace single newlines with line breaks
  ];

  let parsedText = rules.reduce((acc, rule) => acc.replace(rule.regex, rule.replacement), text);
  
  // Wrap the text in paragraphs if not already wrapped
  if (!parsedText.startsWith('<p>')) {
    parsedText = `<p>${parsedText}</p>`;
  }

  return parsedText
    .replace(/<\/p><p>/gim, '</p>\n\n<p>') // Ensure two newlines between paragraphs
    .replace(/<p><\/p>/gim, '') // Remove empty paragraphs
    .replace(/<\/li><br>/gim, '</li>') // Remove extra line breaks after list items
    .replace(/<\/h(\d)><br>/gim, '</h$1>\n\n') // Ensure two newlines after headers
    .replace(/<br>\s*<br>/gim, '<br>'); // Replace double line breaks with single
};

const ChapterGenerator = ({ setDrawerOpen }) => {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [chapterContent, setChapterContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const username = sessionStorage.getItem('username');
  const [polling, setPolling] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);

  // For text highlighting
  const [highlights, setHighlights] = useState({});
  const contentRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const quillRef = useRef(null);

  const [aiDialogOpen, setAIDialogOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [aiPrompt, setAIPrompt] = useState('');
  const [includeFullChapter, setIncludeFullChapter] = useState(false);
  const [aiResponse, setAIResponse] = useState('');

  const [references, setReferences] = useState([]);
  const [referencesDialogOpen, setReferencesDialogOpen] = useState(false);

  const [aiSelectionRange, setAISelectionRange] = useState(null);

  const [aiLoading, setAILoading] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false);

  // Collapse the sidebar when the component mounts
  useEffect(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${CONFIG.SERVER_IP}/api/books/user/${username}`);
      if (response.data && response.data.books) {
        setBooks(response.data.books);
      } else {
        setBooks([]);
      }
    } catch (error) {
      console.error('Error fetching books:', error);
      setBooks([]);
    } finally {
      setLoading(false);
    }
  };

  const handleBookSelection = async (bookName) => {
    const book = books.find((b) => b.book_name === bookName);
    setSelectedBook(book);
    setChapters([]);
    setChapterContent('');
    setCurrentChapterIndex(0);
    await fetchChapters(book);
  };

  const fetchChapters = async (book) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${CONFIG.SERVER_IP}/api/books/${username}/${encodeURIComponent(book.book_name)}/chapters`
      );
      if (response.data && response.data.chapters) {
        setChapters(response.data.chapters);
        if (response.data.chapters.length > 0) {
          handleChapterSelection(response.data.chapters[0], 0);
        }
      } else {
        setChapters([]);
      }
    } catch (error) {
      console.error('Error fetching chapters:', error);
      setChapters([]);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateBook = async () => {
    if (!selectedBook) return;
    setIsGenerating(true);
    setLoading(true);
    try {
      const response = await axios.post(`${CONFIG.SERVER_IP}/api/books/generate`, {
        username: username,
        book_name: selectedBook.book_name,
      });
      if (response.status === 200) {
        setSnackbarMessage('Chapter generation started. This may take a few minutes.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);

        // Reset chapters and start polling
        setChapters([]);
        setChapterContent('');
        setPolling(true);
      } else {
        console.error('Unexpected response status:', response.status);
        setSnackbarMessage('An error occurred while starting chapter generation.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error initiating chapter generation:', error);
      setSnackbarMessage('An error occurred while starting chapter generation.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChapterSelection = (chapter, index) => {
    setCurrentChapterIndex(index);
    const parsedContent = parseMarkdown(chapter.content || '');
    setChapterContent(parsedContent);

    // Restore highlights for this chapter
    setTimeout(() => {
      if (highlights[chapter.chapterTitle]) {
        highlights[chapter.chapterTitle].forEach((highlight) => {
          applyHighlight(highlight);
        });
      }
    }, 0);
  };

  useEffect(() => {
    let intervalId;
    if (polling) {
      intervalId = setInterval(async () => {
        try {
          const response = await axios.get(
            `${CONFIG.SERVER_IP}/api/books/${username}/${encodeURIComponent(selectedBook.book_name)}/chapters`
          );

          if (response.status === 200 && response.data) {
            const { chapters, status } = response.data;

            if (status === 'Completed') {
              if (chapters && chapters.length > 0) {
                setChapters(chapters);
                handleChapterSelection(chapters[0], 0);
              }
              setPolling(false);
              setIsGenerating(false);
              setSnackbarMessage('Chapter generation completed.');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
            } else if (status === 'Error') {
              setPolling(false);
              setIsGenerating(false);
              setSnackbarMessage('An error occurred during chapter generation.');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            } else {
              // Generation is still in progress
              console.log(`Current status: ${status}. Continuing to poll...`);
            }
          }
        } catch (error) {
          console.error('Error fetching chapters during polling:', error);
        }
      }, 10000); // Poll every 10 seconds
    }
    return () => clearInterval(intervalId);
  }, [polling, username, selectedBook]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const handleDownloadBook = async () => {
    if (!selectedBook) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${CONFIG.SERVER_IP}/api/books/download/${username}/${encodeURIComponent(selectedBook.book_name)}/docx`,
        {
          responseType: 'blob',
        }
      );

      // Create a link element, set the blob data, and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedBook.book_name}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading book:', error);
      setSnackbarMessage('An error occurred while downloading the book.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBook = async (bookName) => {
    try {
      const response = await axios.delete(
        `${CONFIG.SERVER_IP}/api/books/${username}/${encodeURIComponent(bookName)}`
      );
      if (response.status === 200) {
        const updatedBooks = books.filter((book) => book.book_name !== bookName);
        setBooks(updatedBooks);
        if (selectedBook && selectedBook.book_name === bookName) {
          setSelectedBook(null);
          setChapters([]);
          setChapterContent('');
          setCurrentChapterIndex(null);
        }
        setSnackbarMessage('Book deleted successfully.');
        setSnackbarSeverity('success');
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error deleting book:', error);
      setSnackbarMessage(
        error.response?.data?.error || 'An error occurred while deleting the book.'
      );
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleDeleteClick = (book) => {
    setBookToDelete(book);
    setDeleteDialogOpen(true);
  };

  // Highlighting functions
  const applyHighlight = (highlight) => {
    const range = document.createRange();
    const startNode = contentRef.current.querySelector(`[data-offset="${highlight.start}"]`);
    const endNode = contentRef.current.querySelector(`[data-offset="${highlight.end}"]`);

    if (startNode && endNode) {
      range.setStart(startNode, 0);
      range.setEnd(endNode, endNode.textContent.length);

      const highlightSpan = document.createElement('span');
      highlightSpan.style.backgroundColor = 'yellow';
      highlightSpan.appendChild(range.extractContents());
      range.insertNode(highlightSpan);
    }
  };

  const handleHighlight = () => {
    if (isEditing) {
      // Editing mode with Quill
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range && range.length > 0) {
        quill.formatText(range.index, range.length, 'background', 'yellow');
      } else {
        showSnackbar('Please select text to highlight.', 'warning');
      }
    } else {
      // Viewing mode using DOM selection
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0).cloneRange();
        if (!range.collapsed) {
          const highlightSpan = document.createElement('span');
          highlightSpan.style.backgroundColor = 'yellow';
          range.surroundContents(highlightSpan);
          selection.removeAllRanges();
          selection.addRange(range);
        } else {
          showSnackbar('Please select text to highlight.', 'warning');
        }
      }
    }
  };

  const handleUndoHighlight = () => {
    if (isEditing) {
      // Editing mode with Quill
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range && range.length > 0) {
        quill.formatText(range.index, range.length, 'background', false);
      } else {
        showSnackbar('Please select highlighted text to remove highlight.', 'warning');
      }
    } else {
      // Viewing mode using DOM selection
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const parentElement = range.commonAncestorContainer.parentElement;
        if (parentElement && parentElement.style.backgroundColor === 'yellow') {
          parentElement.style.backgroundColor = '';
        } else {
          showSnackbar('Please select highlighted text to remove highlight.', 'warning');
        }
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedContent(chapterContent);
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${CONFIG.SERVER_IP}/api/chapters/${username}/${encodeURIComponent(
          selectedBook.book_name
        )}/${encodeURIComponent(chapters[currentChapterIndex].chapterTitle)}`,
        {
          content: editedContent,
        }
      );

      if (response.status === 200) {
        setChapterContent(editedContent);
        setIsEditing(false);

        // Update the chapters array with the new content
        const updatedChapters = [...chapters];
        updatedChapters[currentChapterIndex] = {
          ...updatedChapters[currentChapterIndex],
          content: editedContent,
        };
        setChapters(updatedChapters);

        setSnackbarMessage('Chapter saved successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('An error occurred while saving the chapter.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error saving chapter:', error);
      setSnackbarMessage('An error occurred while saving the chapter.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOpenAIDialog = () => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (!range || range.length === 0) {
      setSnackbarMessage('Please select text before clicking AI.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    const selected = quill.getText(range.index, range.length);
    setSelectedText(selected);
    setAISelectionRange(range); // Store the selection range
    setAIDialogOpen(true);
  };

  const handleCloseAIDialog = () => {
    setAIDialogOpen(false);
    setSelectedText('');
    setAIPrompt('');
    setAIResponse('');
  };

  const handleGenerateAIResponse = async () => {
    setAILoading(true); // Start loading indicator
    try {
      const response = await axios.post(`${CONFIG.SERVER_IP}/api/ai/generate`, {
        username,
        prompt: aiPrompt,
        selectedText,
        fullChapter: includeFullChapter ? editedContent : '',
      });

      if (response.status === 200) {
        setAIResponse(response.data.aiResponse);
      } else {
        setSnackbarMessage('An error occurred while generating AI response.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error generating AI response:', error);
      setSnackbarMessage('An error occurred while generating AI response.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setAILoading(false); // Stop loading indicator
    }
  };

  const handleReplaceWithAIResponse = () => {
    const quill = quillRef.current.getEditor();
    const range = aiSelectionRange; // Use the stored selection range
    if (!range || range.length === 0) {
      setSnackbarMessage('No text is selected.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    quill.deleteText(range.index, range.length);
    quill.insertText(range.index, aiResponse);
    setAIDialogOpen(false);
    setAIResponse('');
    setSelectedText('');
    setAIPrompt('');
    setAISelectionRange(null); // Clear the stored selection range
  };

  const handleOpenReferencesDialog = () => {
    setReferencesDialogOpen(true);
  };

  useEffect(() => {
    // Assume chapterContent is the raw HTML string
    const normalizedContent = chapterContent.replace(/(<br\s*\/?>\s*){3,}/g, '<br><br>');
    setChapterContent(normalizedContent);
  }, [chapterContent]);

  // Helper function to display snackbar messages
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handlePreviousChapter = () => {
    if (currentChapterIndex > 0) {
      const newIndex = currentChapterIndex - 1;
      handleChapterSelection(chapters[newIndex], newIndex);
    }
  };

  const handleNextChapter = () => {
    if (currentChapterIndex < chapters.length - 1) {
      const newIndex = currentChapterIndex + 1;
      handleChapterSelection(chapters[newIndex], newIndex);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',    /* Use full width */
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* AppBar */}
      <AppBar position="static" className="app-bar">
        <Toolbar>
          <Typography variant="h6" className="app-bar-title">
            {selectedBook ? selectedBook.book_name : 'Your Books'}
          </Typography>
          {selectedBook && (
            <FormControl variant="outlined" className="book-select-form" size="small">
              <InputLabel className="book-select-label">Select Book</InputLabel>
              <Select
                value={selectedBook.book_name}
                onChange={(e) => handleBookSelection(e.target.value)}
                label="Select Book"
                className="book-select"
                size="small"
              >
                {books.map((book) => (
                  <MenuItem key={book.book_name} value={book.book_name}>
                    {book.book_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box className="content-container">
        {loading ? (
          <Box className="loading-container">
            <CircularProgress />
          </Box>
        ) : selectedBook ? (
          <Box className="book-content-container">
            {/* Header */}
            <Box className="book-content-header">
              <Box className="actions-container">
                <Tooltip title="Generate all chapters for this book">
                  <Button
                    variant="contained"
                    className={`generate-button${polling ? ' blinking-button' : ''}`}
                    onClick={handleGenerateBook}
                    startIcon={isGenerating ? <CircularProgress size={20} color="inherit" /> : <MenuBookIcon />}
                    disabled={isGenerating}
                    size="small"
                  >
                    {isGenerating ? 'Generating...' : 'Generate Book'}
                  </Button>
                </Tooltip>
                <Tooltip title="Download as DOCX">
                  <Button
                    variant="contained"
                    className="download-button"
                    onClick={handleDownloadBook}
                    startIcon={<DescriptionIcon />}
                    size="small"
                  >
                    Download DOCX
                  </Button>
                </Tooltip>
                <FormControl variant="outlined" className="chapter-select-form" size="small">
                  <InputLabel className="chapter-select-label">Select Chapter</InputLabel>
                  <Select
                    value={
                      chapters.length > 0 && currentChapterIndex !== null
                        ? chapters[currentChapterIndex].chapterTitle
                        : ''
                    }
                    onChange={(e) => {
                      const index = chapters.findIndex(
                        (chapter) => chapter.chapterTitle === e.target.value
                      );
                      handleChapterSelection(chapters[index], index);
                    }}
                    label="Select Chapter"
                    className="chapter-select"
                    size="small"
                  >
                    {chapters.map((chapter, index) => (
                      <MenuItem key={index} value={chapter.chapterTitle}>
                        {chapter.chapterTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* Fixed Editing Tools */}
            <Box className="fixed-editing-tools">
              <Button
                variant="contained"
                onClick={handlePreviousChapter}
                className="chapter-navigation-button"
                disabled={currentChapterIndex === 0}
                size="small"
              >
                Previous
              </Button>
              <Box className="center-buttons">
                <Button
                  variant="contained"
                  startIcon={<HighlightIcon />}
                  onClick={handleHighlight}
                  className="highlight-button"
                  size="small"
                >
                  Highlight
                </Button>
                <Button
                  variant="contained"
                  startIcon={<UndoIcon />}
                  onClick={handleUndoHighlight}
                  className="undo-highlight-button"
                  size="small"
                >
                  Undo
                </Button>
                {isEditing ? (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                      className="save-button"
                      size="small"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<AIFixIcon />}
                      onClick={handleOpenAIDialog}
                      className="ai-button"
                      size="small"
                    >
                      AI
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={handleEditClick}
                    className="edit-button"
                    size="small"
                  >
                    Edit
                  </Button>
                )}
              </Box>
              <Button
                variant="contained"
                onClick={handleNextChapter}
                className="chapter-navigation-button"
                disabled={currentChapterIndex === chapters.length - 1}
                size="small"
              >
                Next
              </Button>
            </Box>

            {/* Chapter Content */}
            <Box className="chapter-content">
              {isEditing ? (
                <ReactQuill
                  ref={quillRef}
                  value={editedContent}
                  onChange={setEditedContent}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link', 'image'],
                      ['clean'],
                    ],
                  }}
                />
              ) : (
                <div
                  ref={contentRef}
                  className="chapter-text"
                  dangerouslySetInnerHTML={{ __html: chapterContent }}
                />
              )}
            </Box>
          </Box>
        ) : (
          /* Book List */
          <Box className="book-list-container">
            <Box className="book-list-header">
              <Typography variant="h4" className="book-list-title">
                Your Books
              </Typography>
            </Box>
            <List className="book-list">
              {books.map((book) => (
                <React.Fragment key={book.book_name}>
                  <ListItem
                    button
                    onClick={() => handleBookSelection(book.book_name)}
                    className="book-list-item"
                  >
                    <ListItemText
                      primary={book.book_name}
                      secondary={new Date(book.updated_at).toLocaleString()}
                    />
                    <Tooltip title="Delete Book">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(book);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}
      </Box>

      {/* Snackbar and Dialogs */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        PaperProps={{
          className: 'delete-dialog-paper',
        }}
        sx={{
          zIndex: 1500,
        }}
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the book "{bookToDelete?.book_name}"? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteBook(bookToDelete.book_name);
              setDeleteDialogOpen(false);
            }}
            color="error"
            variant="contained"
            className="delete-button"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* AI Assistant Dialog */}
      <Dialog open={aiDialogOpen} onClose={handleCloseAIDialog} fullWidth maxWidth="md">
        <DialogTitle>AI Assistant</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Selected Text:</Typography>
          <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
            {selectedText}
          </Typography>
          <TextField
            label="AI Prompt"
            fullWidth
            multiline
            rows={4}
            value={aiPrompt}
            onChange={(e) => setAIPrompt(e.target.value)}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeFullChapter}
                onChange={(e) => setIncludeFullChapter(e.target.checked)}
              />
            }
            label="Include full chapter as context"
          />
          {aiLoading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : aiResponse && (
            <>
              <Typography variant="subtitle1">AI Response:</Typography>
              <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                {aiResponse}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!aiResponse ? (
            <Button onClick={handleGenerateAIResponse} color="primary" disabled={aiLoading}>
              Generate
            </Button>
          ) : (
            <Button onClick={handleReplaceWithAIResponse} color="primary">
              Replace
            </Button>
          )}
          <Button onClick={handleCloseAIDialog} disabled={aiLoading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChapterGenerator;

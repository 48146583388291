// Level4.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Paper,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import {
  Person as PersonIcon,
  Topic as TopicIcon,
  Category as CategoryIcon,
  Style as StyleIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  HelpOutline as HelpOutlineIcon,
  Title as TitleIcon,
  List as ListIcon,
  AccountCircle as AccountCircleIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Book as BookIcon,
  Extension as ExtensionIcon, // Replaced Framework with Extension
  WorkOutline as WorkOutlineIcon, // Replaced ExperienceIcon with WorkOutlineIcon
  Build as BuildIcon,
  CheckCircle as CheckCircleIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { FaUpload, FaFileAlt } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import CONFIG from '../utils/config';
import '../styles/Level4.css';

const Level4 = ({ setView, bookInfo, setBookInfo }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [persona, setPersona] = useState(bookInfo.persona || 'businessLeader');
  const [topic, setTopic] = useState(bookInfo.topic || '');
  const [field, setField] = useState(bookInfo.field || '');
  const [style, setStyle] = useState(bookInfo.style || '');
  const [targetAudience, setTargetAudience] = useState(bookInfo.targetAudience || '');
  const [problem, setProblem] = useState(bookInfo.problem || '');
  const [bookTitle, setBookTitle] = useState(bookInfo.bookTitle || '');
  const [keyPoints, setKeyPoints] = useState(bookInfo.keyPoints || '');
  const [background, setBackground] = useState(bookInfo.background || '');
  const [examples, setExamples] = useState(bookInfo.examples || '');
  const [potentialChapters, setPotentialChapters] = useState(bookInfo.potentialChapters || '');
  const [methodologies, setMethodologies] = useState(bookInfo.methodologies || '');
  const [personalExperiences, setPersonalExperiences] = useState(
    bookInfo.personalExperiences || ''
  );
  const [existingWorks, setExistingWorks] = useState(bookInfo.existingWorks || '');

  const [errors, setErrors] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const steps = [
    'Step 1: Persona',
    'Step 2: Topic',
    'Step 3: Field',
    'Step 4: Style',
    'Step 5: Target Audience',
    'Step 6: Problem',
    'Step 7: Book Title',
    'Step 8: Key Points',
    'Step 9: Background',
    'Step 10: Examples',
    'Step 11: Potential Chapters',
    'Step 12: Methodologies',
    'Step 13: Personal Experiences',
    'Step 14: Existing Works',
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const username = sessionStorage.getItem('username') || 'defaultUser';
    
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`${CONFIG.SERVER_IP}/api/upload/files?username=${username}`);
        setUploadedFiles(response.data.files || []);
      } catch (error) {
        console.error('Error loading files:', error);
        setUploadedFiles([]);
      }
    };
    
    fetchFiles();
  }, []);

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('username', sessionStorage.getItem('username') || 'defaultUser');
      
      try {
        const response = await axios.post(`${CONFIG.SERVER_IP}/api/upload`, formData);
        setSuccess('Files uploaded successfully.');
        setUploadedFiles([...uploadedFiles, ...response.data.files]);
      } catch (error) {
        const errorMessage = error.response && error.response.data
          ? error.response.data
          : 'Failed to upload files.';
        setError(errorMessage);
      } finally {
        setLoading(false);
        setSnackbarOpen(true);
      }
    }
  };

  const handleDeleteFile = async (fileName) => {
    const username = sessionStorage.getItem('username') || 'defaultUser';

    try {
      await axios.delete(`${CONFIG.SERVER_IP}/api/upload/delete`, {
        params: { username, fileName },
      });
      setSuccess('File deleted successfully.');
      setUploadedFiles(uploadedFiles.filter((file) => file !== fileName));
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Failed to delete file.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleNext = () => {
    // Validate the input of the current step before moving to the next
    const newErrors = {};
    if (activeStep === 1 && !topic.trim())
      newErrors.topic = 'Topic is required';
    if (activeStep === 2 && !field.trim())
      newErrors.field = 'Field is required';
    if (activeStep === 3 && !style.trim())
      newErrors.style = 'Style is required';
    if (activeStep === 4 && !targetAudience.trim())
      newErrors.targetAudience = 'Target Audience is required';
    if (activeStep === 5 && !problem.trim())
      newErrors.problem = 'Problem is required';
    if (activeStep === 6 && !bookTitle.trim())
      newErrors.bookTitle = 'Book Title is required';
    if (activeStep === 7 && !keyPoints.trim())
      newErrors.keyPoints = 'Key Points are required';
    if (activeStep === 8 && !background.trim())
      newErrors.background = 'Background is required';
    if (activeStep === 9 && !examples.trim())
      newErrors.examples = 'Examples are required';
    if (activeStep === 10 && !potentialChapters.trim())
      newErrors.potentialChapters = 'Potential Chapters are required';
    if (activeStep === 11 && !methodologies.trim())
      newErrors.methodologies = 'Methodologies are required';
    if (activeStep === 12 && !personalExperiences.trim())
      newErrors.personalExperiences = 'Personal Experiences are required';
    if (activeStep === 13 && !existingWorks.trim())
      newErrors.existingWorks = 'Existing Works are required';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (activeStep === steps.length - 1) {
        // Open confirmation dialog
        setConfirmOpen(true);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleConfirm = () => {
    // Save the information and proceed
    setBookInfo({
      persona,
      topic,
      field,
      style,
      targetAudience,
      problem,
      bookTitle,
      keyPoints,
      background,
      examples,
      potentialChapters,
      methodologies,
      personalExperiences,
      existingWorks,
    });
    setConfirmOpen(false);
    // Proceed to generate outline or next view
    setView('generateOutline');
  };

  return (
    <Box className="level4-container">
       <Box className="level3-banner">
        <Typography variant="h3" className="level3-title">
          <b>Insight Architect</b>
        </Typography>
      </Box>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!isMobile}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        className="stepper"
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content">
        {activeStep === 0 && (
          <Box className="step-input">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="level4-form-label">
                <PersonIcon className="form-icon" /> <b>Choose Your Persona</b>
              </FormLabel>
              <RadioGroup
                aria-label="persona"
                name="persona"
                value={persona}
                onChange={(e) => setPersona(e.target.value)}
              >
                <FormControlLabel
                  value="businessLeader"
                  control={<Radio />}
                  label="Business Leader"
                />
                <FormControlLabel
                  value="academicResearcher"
                  control={<Radio />}
                  label="Academic/Researcher"
                />
                <FormControlLabel
                  value="selfHelpGuru"
                  control={<Radio />}
                  label="Self-help Guru"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {activeStep === 1 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <TopicIcon className="form-icon" /> <b>Main Topic or Subject</b>
            </FormLabel>
            <TextField
              fullWidth
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter the main topic or subject"
              variant="outlined"
              error={!!errors.topic}
              helperText={errors.topic}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <CategoryIcon className="form-icon" /> <b>Related Field or Area</b>
            </FormLabel>
            <TextField
              fullWidth
              value={field}
              onChange={(e) => setField(e.target.value)}
              placeholder="Enter the field or area"
              variant="outlined"
              error={!!errors.field}
              helperText={errors.field}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <StyleIcon className="form-icon" /> <b>Preferred Writing Style or Tone</b>
            </FormLabel>
            <TextField
              fullWidth
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              placeholder="Enter the preferred style or tone"
              variant="outlined"
              error={!!errors.style}
              helperText={errors.style}
            />
          </Box>
        )}
        {activeStep === 4 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <GroupIcon className="form-icon" /> <b>Who is your target audience?</b>
            </FormLabel>
            <TextField
              fullWidth
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              placeholder="Enter your target audience"
              variant="outlined"
              error={!!errors.targetAudience}
              helperText={errors.targetAudience}
            />
          </Box>
        )}
        {activeStep === 5 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <HelpOutlineIcon className="form-icon" />{' '}
              <b>Main Problem, Question, or Challenge</b>
            </FormLabel>
            <TextField
              fullWidth
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              placeholder="Describe the main problem or challenge"
              variant="outlined"
              error={!!errors.problem}
              helperText={errors.problem}
            />
          </Box>
        )}
        {activeStep === 6 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <TitleIcon className="form-icon" /> <b>Preferred Book Title or Ideas</b>
            </FormLabel>
            <TextField
              fullWidth
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              placeholder="Enter your book title ideas"
              variant="outlined"
              error={!!errors.bookTitle}
              helperText={errors.bookTitle}
            />
          </Box>
        )}
        {activeStep === 7 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <ListIcon className="form-icon" />{' '}
              <b>3-5 Key Points or Insights</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={keyPoints}
              onChange={(e) => setKeyPoints(e.target.value)}
              placeholder="List 3-5 key points or insights you want readers to take away"
              variant="outlined"
              error={!!errors.keyPoints}
              helperText={errors.keyPoints}
            />
          </Box>
        )}
        {activeStep === 8 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <DescriptionIcon className="form-icon" />{' '}
              <b>Your Background</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={background}
              onChange={(e) => setBackground(e.target.value)}
              placeholder="Provide a brief description of your background related to this topic"
              variant="outlined"
              error={!!errors.background}
              helperText={errors.background}
            />
          </Box>
        )}
        {activeStep === 9 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <InsertDriveFileIcon className="form-icon" />{' '}
              <b>Specific Examples, Case Studies, or Data</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={examples}
              onChange={(e) => setExamples(e.target.value)}
              placeholder="Are there any specific examples, case studies, or data you want to include?"
              variant="outlined"
              error={!!errors.examples}
              helperText={errors.examples}
            />
          </Box>
        )}
        {activeStep === 10 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <BookIcon className="form-icon" />{' '}
              <b>Potential Chapters or Rough Outline</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={potentialChapters}
              onChange={(e) => setPotentialChapters(e.target.value)}
              placeholder="Provide a rough outline or list of potential chapters for your book"
              variant="outlined"
              error={!!errors.potentialChapters}
              helperText={errors.potentialChapters}
            />
          </Box>
        )}
        {activeStep === 11 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <ExtensionIcon className="form-icon" />{' '}
              <b>Specific Methodologies, Frameworks, or Theories</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={methodologies}
              onChange={(e) => setMethodologies(e.target.value)}
              placeholder="Enter any specific methodologies, frameworks, or theories you want to include"
              variant="outlined"
              error={!!errors.methodologies}
              helperText={errors.methodologies}
            />
          </Box>
        )}
        {activeStep === 12 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <WorkOutlineIcon className="form-icon" />{' '}
              <b>Personal Experiences or Research Findings</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={personalExperiences}
              onChange={(e) => setPersonalExperiences(e.target.value)}
              placeholder="Share 2-3 personal experiences or research findings relevant to the topic"
              variant="outlined"
              error={!!errors.personalExperiences}
              helperText={errors.personalExperiences}
            />
          </Box>
        )}
        {activeStep === 13 && (
          <Box className="step-input">
            <FormLabel component="legend" className="level4-form-label">
              <BuildIcon className="form-icon" />{' '}
              <b>Existing Works on the Subject</b>
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={existingWorks}
              onChange={(e) => setExistingWorks(e.target.value)}
              placeholder="Are there any existing works on this subject? How will yours be different?"
              variant="outlined"
              error={!!errors.existingWorks}
              helperText={errors.existingWorks}
            />
          </Box>
        )}
        <Box className="step-buttons">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="back-button"
            startIcon={<ArrowBackIcon />} // Add an icon
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className="next-button"
            endIcon={
              activeStep === steps.length - 1 ? <DescriptionIcon /> : <ArrowForwardIcon />
            }
          >
            {activeStep === steps.length - 1 ? 'Generate Outline' : 'Next'}
          </Button>
        </Box>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-dialog"
        maxWidth="sm"
        fullWidth
        classes={{ paper: 'confirm-dialog-paper' }}
      >
        <DialogTitle id="confirm-dialog" className="confirm-dialog-title">
          <CheckCircleIcon className="confirm-dialog-icon" />
          Confirm Your Selections
        </DialogTitle>
        <DialogContent dividers>
          <Box className="confirm-content">
            {/* Display all the selections here */}
            <Typography variant="h6" className="confirm-label">
              Persona:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {persona === 'businessLeader' && 'Business Leader'}
              {persona === 'academicResearcher' && 'Academic/Researcher'}
              {persona === 'selfHelpGuru' && 'Self-help Guru'}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Topic or Subject:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {topic}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Related Field or Area:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {field}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Preferred Writing Style or Tone:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {style}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Target Audience:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {targetAudience}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Main Problem, Question, or Challenge:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {problem}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Preferred Book Title or Ideas:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {bookTitle}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              3-5 Key Points or Insights:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {keyPoints}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Your Background:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {background}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Specific Examples, Case Studies, or Data:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {examples}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Potential Chapters or Rough Outline:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {potentialChapters}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Specific Methodologies, Frameworks, or Theories:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {methodologies}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Personal Experiences or Research Findings:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {personalExperiences}
            </Typography>
            <Divider className="confirm-divider" />
            <Typography variant="h6" className="confirm-label">
              Existing Works on the Subject:
            </Typography>
            <Typography variant="body1" className="confirm-text">
              {existingWorks}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className="confirm-dialog-actions">
          <Button
            onClick={() => setConfirmOpen(false)}
            className="dialog-back-button"
          >
            Back
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            className="dialog-confirm-button"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* File Upload Section */}
      <Paper className="file-upload-section" elevation={3}>
        <Typography variant="h6" className="file-upload-title">
          Upload Context Files
        </Typography>
        <input
          accept=".txt,.doc,.docx,.pdf"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<FaUpload />}
            className="upload-button"
          >
            Choose Files
          </Button>
        </label>
        {loading && <LinearProgress className="upload-progress" />}
      </Paper>

      {/* Uploaded Files List with Enhanced Styling */}
      {uploadedFiles.length > 0 && (
        <Paper className="uploaded-files" elevation={3}>
          <Typography variant="h6" className="uploaded-files-title">
            Uploaded Files
          </Typography>
          <List>
            {uploadedFiles.map((file, index) => (
              <ListItem key={index} className="file-item">
                <ListItemIcon>
                  <FaFileAlt className="file-icon" />
                </ListItemIcon>
                <ListItemText primary={file} />
                <ListItemSecondaryAction>
                  <Tooltip title="Delete File">
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteFile(file)}
                      className="delete-icon-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* Snackbar for Error and Success Messages */}
      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
      {success && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Level4;

// frontend/src/components/pages/Outline.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  Backdrop,
} from '@mui/material';
import axios from 'axios';
import CONFIG from '../utils/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import '../styles/Outline.css';

// Import the loading.gif image
import loadingGif from '../assets/loading.gif';

const Outline = ({ setView, bookInfo, setBookInfo, level }) => {
  const [outline, setOutline] = useState(bookInfo.rawOutline || '');
  const [aiModel] = useState('gpt'); // Assuming 'gpt' is the default model
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // For error handling
  const [success, setSuccess] = useState(null); // For success messages

  useEffect(() => {
    handleGenerate();
  }, []);

  const handleBack = () => {
    // Navigate back based on the current level
    const levelViews = {
      1: 'level1',
      2: 'level2',
      3: 'level3', 
      4: 'level4',
    };
    setView(levelViews[level] || 'description');
    setBookInfo({
      ...bookInfo,
      rawOutline: outline,
      tableOfContent: parseTableOfContent(outline),
    });
  };

  const handleGenerate = async () => {
    // Clear the textareas before generating
    setOutline('');

    const username = sessionStorage.getItem('username');
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      let response;

      if (level === 1) {
        // Level 1 generation
        response = await axios.post(`${CONFIG.SERVER_IP}/api/level1/outline`, {
          topic: bookInfo.topic,
          username,
          model: aiModel,
          field: bookInfo.field,
          writing_style: bookInfo.style,
          persona: bookInfo.persona,
        });
      } else if (level === 2) {
        // Level 2 generation
        response = await axios.post(`${CONFIG.SERVER_IP}/api/level2/outline`, {
          persona: bookInfo.persona,
          topic: bookInfo.topic,
          field: bookInfo.field,
          style: bookInfo.style,
          targetAudience: bookInfo.targetAudience,
          problem: bookInfo.problem,
          bookTitle: bookInfo.bookTitle,
          username,
          model: aiModel,
        });
      } else if (level === 3) {
        // Level 3 generation
        response = await axios.post(`${CONFIG.SERVER_IP}/api/level3/outline`, {
          persona: bookInfo.persona,
          topic: bookInfo.topic,
          field: bookInfo.field,
          style: bookInfo.style,
          targetAudience: bookInfo.targetAudience,
          problem: bookInfo.problem,
          bookTitle: bookInfo.bookTitle,
          keyPoints: bookInfo.keyPoints,
          background: bookInfo.background,
          examples: bookInfo.examples,
          username,
          model: aiModel,
          level: 3,
        });
      } else if (level === 4) {
        // Level 4 generation
        response = await axios.post(`${CONFIG.SERVER_IP}/api/level4/outline`, {
          persona: bookInfo.persona,
          topic: bookInfo.topic,
          field: bookInfo.field,
          style: bookInfo.style,
          targetAudience: bookInfo.targetAudience,
          problem: bookInfo.problem,
          bookTitle: bookInfo.bookTitle,
          keyPoints: bookInfo.keyPoints,
          background: bookInfo.background,
          examples: bookInfo.examples,
          potentialChapters: bookInfo.potentialChapters,
          methodologies: bookInfo.methodologies,
          personalExperiences: bookInfo.personalExperiences,
          existingWorks: bookInfo.existingWorks,
          username,
          model: aiModel,
          level,
        });
      } else {
        // Other levels' generation
        response = await axios.post(`${CONFIG.SERVER_IP}/api/outlines/generate`, {
          title: bookInfo.title,
          description: bookInfo.description,
          numChapters: bookInfo.numChapters,
          model: aiModel,
          username,
          level,
        });
      }

      if (response.data && response.data.outline) {
        const newOutline = response.data.outline;

        // Format the outline into markdown
        const formattedOutline = newOutline
          .map(
            (chapter) =>
              `# ${chapter.chapterTitle}\n\n${chapter.sections
                .map(
                  (section) =>
                    `## ${section.title}\n${section.subsections
                      .map((subsection) => `- ${subsection.plainText}`)
                      .join('\n')}`
                )
                .join('\n\n')}`
          )
          .join('\n\n');

        setOutline(formattedOutline);
        setBookInfo({
          ...bookInfo,
          title: response.data.title || bookInfo.topic,
          description: bookInfo.topic,
          numChapters: bookInfo.numChapters || newOutline.length,
          rawOutline: formattedOutline,
          tableOfContent: newOutline,
        });
        setSuccess('Outline generated successfully.');
      } else {
        throw new Error('Invalid response from the server.');
      }
    } catch (error) {
      console.error('Error generating outline:', error.response?.data || error.message);
      setError('Failed to generate outline. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    // Update bookInfo with the generated outline
    setBookInfo({
      ...bookInfo,
      rawOutline: outline,
      tableOfContent: parseTableOfContent(outline),
    });

    // Navigate to the next view (e.g., 'chapterGenerator')
    setView('chapterGenerator');
  };

  const handleCloseSnackbar = () => {
    setError(null);
    setSuccess(null);
  };

  const parseTableOfContent = (content) => {
    // Existing parseTableOfContent function
    // ...
  };

  return (
    <Box className="content-outline">
      <Typography variant="h4" className="outline-title">
        {`${bookInfo.title} — Outline`}
      </Typography>

      <Box className="markdown-container">
        <Box className="markdown-preview">
          <ReactMarkdown
            children={outline}
            remarkPlugins={[remarkGfm]}
            className="markdown-rendered"
          />
        </Box>
      </Box>

      <Box className="button-container">
        <Button variant="outlined" color="secondary" onClick={handleBack} disabled={loading}>
          Back
        </Button>
        {/*
        
        */}
        <Button variant="contained" color="error" onClick={handleNext} disabled={loading}>
          Generate Book
        </Button>
        <Button variant="contained" color="info" onClick={handleGenerate} disabled={loading}>
          Re-Generate
        </Button>
      </Box>

      {/* Loading overlay */}
      <Backdrop open={loading} className="loading-backdrop">
        <Box className="loading-container">
          <Typography variant="h6" style={{ marginBottom: '20px', color: '#fff' }}>
            Generating Outline...
          </Typography>
          <img src={loadingGif} alt="Loading" className="loading-gif" />
        </Box>
      </Backdrop>

      {/* Snackbar for Success Messages */}
      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className="alert-snackbar"
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>

      {/* Snackbar for Error Messages */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className="alert-snackbar"
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Outline;
// frontend/src/components/Sidebar.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Box,
  Avatar,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  MenuBook as MenuBookIcon,
  Book as BookIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Bookmarks as BookmarksIcon,
  Home as HomeIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@mui/icons-material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import '../styles/Sidebar.css';

function Sidebar({ drawerOpen, setDrawerOpen, setView, isMobile }) {
  const navigate = useNavigate();
  const [openLevels, setOpenLevels] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('home');

  const username = sessionStorage.getItem('username') || 'User';

  const handleLevelsClick = (e) => {
    e.stopPropagation();
    setOpenLevels(!openLevels);
  };

  const handleLogout = (e) => {
    e.stopPropagation();
    sessionStorage.clear();
    navigate('/login');
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLevelClick = (level, e) => {
    e.stopPropagation();
    setView(`${level}`);
    setSelectedIndex(`${level}`);
  };

  const handleMenuItemClick = (viewName, e) => {
    e.stopPropagation();
    setView(viewName);
    setSelectedIndex(viewName);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleHomeClick = (e) => {
    e.stopPropagation();
    setView('home');
    setSelectedIndex('home');
  };

  const levels = ['Beginner', 'Novice', 'Intermediate', 'Advanced', 'Expert'];

  const drawerContent = (
    <div className={`sidebar-content ${!drawerOpen ? 'collapsed' : ''}`}>
      {/* Toggle Button */}
      <Box className="toggle-button-container">
        <Tooltip title={drawerOpen ? 'Collapse Sidebar' : 'Expand Sidebar'} placement="right">
          <IconButton
            onClick={handleDrawerToggle}
            className="sidebar-toggle-button"
            size="large"
            color="inherit"
          >
            {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* User Info */}
      <Box className="user-info">
        <Avatar className="user-avatar">
          <AccountCircleIcon />
        </Avatar>
        {drawerOpen && <div className="username">{username}</div>}
      </Box>

      <Divider className="sidebar-divider" />

      {/* Main Menu Items */}
      <List component="nav" className="menu-list">
        {/* Home Section */}
        <ListItemButton
          onClick={handleHomeClick}
          className="sidebar-listitem"
          selected={selectedIndex === 'home'}
        >
          <ListItemIcon className="sidebar-icon">
            <HomeIcon />
          </ListItemIcon>
          {drawerOpen ? (
            <ListItemText primary="Home" className="sidebar-text bold" />
          ) : (
            <Tooltip title="Home" placement="right">
              <ListItemText primary="" className="sidebar-text bold" />
            </Tooltip>
          )}
        </ListItemButton>

        {/* Non-Fiction Section */}
        <ListItemButton
          onClick={handleLevelsClick}
          className="sidebar-listitem"
          selected={selectedIndex === 'levels'}
        >
          <ListItemIcon className="sidebar-icon">
            <MenuBookIcon />
          </ListItemIcon>
          {drawerOpen ? (
            <ListItemText 
              primary="Non-Fiction" 
              className="sidebar-text bold non-fiction-text" 
            />
          ) : (
            <Tooltip title="Non-Fiction" placement="right">
              <ListItemText primary="" className="sidebar-text bold" />
            </Tooltip>
          )}
          {drawerOpen &&
            (openLevels ? (
              <ExpandLess className="sidebar-icon" />
            ) : (
              <ExpandMore className="sidebar-icon" />
            ))}
        </ListItemButton>
        <Collapse in={openLevels} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {levels.map((level) => (
              <ListItemButton
                sx={{ pl: drawerOpen ? 4 : 2 }}
                key={level}
                onClick={(e) => handleLevelClick(level, e)}
                className="sidebar-listitem"
                selected={selectedIndex === `${level}`}
                disabled={level === 'Expert'}
              >
                <ListItemIcon className="sidebar-icon">
                  <BookIcon fontSize="small" />
                </ListItemIcon>
                {drawerOpen ? (
                  <ListItemText primary={`${level}`} className="sidebar-text bold" />
                ) : (
                  <Tooltip title={level} placement="right">
                    <ListItemText primary="" className="sidebar-text bold" />
                  </Tooltip>
                )}
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <Divider className="sidebar-divider" />
        {/* Your Books */}
        <ListItemButton
          onClick={(e) => handleMenuItemClick('chapterGenerator', e)}
          className="sidebar-listitem your-books"
          selected={selectedIndex === 'chapterGenerator'}
        >
          <ListItemIcon className="sidebar-icon">
            <LibraryBooksIcon />
          </ListItemIcon>
          {drawerOpen ? (
            <ListItemText primary="Your Books" className="sidebar-text" />
          ) : (
            <Tooltip title="Your Books" placement="right">
              <ListItemText primary="" className="sidebar-text" />
            </Tooltip>
          )}
        </ListItemButton>
        {/* Fiction Playground Menu Item */}
        <Divider className="sidebar-divider" />
        <br></br>
        <ListItemButton
          onClick={(e) => handleMenuItemClick('playground', e)}
          className="sidebar-listitem fiction-playground"
          selected={selectedIndex === 'playground'}
        >
          <ListItemIcon className="sidebar-icon">
            <NewReleasesIcon />
          </ListItemIcon>
          {drawerOpen ? (
            <ListItemText primary="Fiction Playground" className="sidebar-text" />
          ) : (
            <Tooltip title="Fiction Playground" placement="right">
              <ListItemText primary="" className="sidebar-text" />
            </Tooltip>
          )}
          <span className="new-marker">New</span>
        </ListItemButton>
      </List>

      <Divider className="sidebar-divider" />

      {/* Logout Button */}
      <Box className="logout-container">
        <ListItemButton onClick={handleLogout} className="sidebar-listitem logout-button">
          <ListItemIcon className="sidebar-icon">
            <ExitToAppIcon />
          </ListItemIcon>
          {drawerOpen ? (
            <ListItemText primary="Logout" className="sidebar-text bold" />
          ) : (
            <Tooltip title="Logout" placement="right">
              <ListItemText primary="" className="sidebar-text bold" />
            </Tooltip>
          )}
        </ListItemButton>
      </Box>
    </div>
  );

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true,
      }}
      className={`sidebar-drawer ${!drawerOpen ? 'collapsed' : ''}`}
      classes={{
        paper: `sidebar-paper ${!drawerOpen ? 'collapsed' : ''}`,
      }}
    >
      {drawerContent}
    </Drawer>
  );
}

export default Sidebar;
